/**
 * @file JS file for the hero media component.
 */

(function heromediaScript($, Drupal) {
  Drupal.behaviors.heromedia = {
    attach(context) {
      var $heroMedia = $(".hero-media");
      doVideoCookieConsent();
      window.addEventListener('CookiebotOnAccept', doVideoCookieConsent);
      window.addEventListener('CookiebotOnDecline', doVideoCookieConsent);


      function heroMedia() {
        var $heroVideoButton = $(".hero-media__cta-button");
        var $visibleStuff = $(".hero-media__visible-item");
        var $hiddenStuff = $(".hero-media__hidden-item");
        var $heroVideoIframe = $(".hero-media iframe");
        var $heroReadMore = $(".hero-media__more");

        $heroVideoButton.click(function (e) {
          e.preventDefault();
          $(this)
            .closest($heroMedia)
            .find($hiddenStuff)
            .removeClass("hero-media__hidden-item")
            .addClass("hero-media__visible-item");
          $(this)
            .closest($heroMedia)
            .addClass('hero-media__video-playing');
          $(this)
            .closest($heroMedia)
            .find($visibleStuff)
            .addClass("hero-media__hidden-item")
            .removeClass("hero-media__visible-item");
          $(this)
            .closest($heroMedia)
            .find($heroVideoIframe)
            .attr(
              "src",
              $(this)
                .closest($heroMedia)
                .find($heroVideoIframe)
                .attr("src")
                .replace("autoplay=0", "autoplay=1")
            );
          $(this)
            .closest($heroReadMore)
            .addClass("hero-media__hidden-item")
            .removeClass("hero-media__visible-item");
        });
      }

      function doVideoCookieConsent() {
        var marketingconsent = getCookie('cookiebot-consent--marketing');

        if (parseInt(marketingconsent) === 1) {
          $('.hero-media__cta-button').html('Play video');
          heroMedia();
        }
        else if (parseInt(marketingconsent) === 0) {
          $('.hero-media__cta-button').html('<a href="javascript:Cookiebot.renew()">The video provider requires that you accept marketing and statistics cookies in order to view this video.</a>').off('click');
        }
      }

      function showMore() {
        var $showMoreButton = $(".hero-media__more");
        $showMoreButton.unbind().click(function (e) {
          e.preventDefault();
          var $heroParent = $(this)
            .closest($heroMedia)
            .parents();

          if ($heroParent.hasClass("building-block")) {
            var $scrollTo = $heroParent.next();
          } else {
            var $scrollTo = $('.main-content');
          }
          $("html, body").animate(
            {
              scrollTop: $scrollTo.offset().top
            },
            500
          );
        });
      }
      showMore();

      function getCookie(cname) {
        let name = cname + "=";
        let decodedCookie = decodeURIComponent(document.cookie);
        let ca = decodedCookie.split(';');
        for (let i = 0; i < ca.length; i++) {
          let c = ca[i];
          while (c.charAt(0) == ' ') {
            c = c.substring(1);
          }
          if (c.indexOf(name) == 0) {
            return c.substring(name.length, c.length);
          }
        }
        return "";
      }
    }
  };
})(jQuery, Drupal);
